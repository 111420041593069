<template>
  <div id="app">
    <div v-if="!$auth.loading && !$apollo.loading">
      <NavSignedInUser v-if="$auth.isAuthenticated" />
      <router-view />
    </div>
    <div v-else class="pageloader is-active">
      <span class="title">جاري التحميل</span>
    </div>
  </div>
</template>
<script>
import NavSignedInUser from "./components/partials/NavSignedInUser.vue";
export default {
  name: "app",
  components: {
    NavSignedInUser
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "برنامج الخمس",
    bodyAttrs: {
      class: "has-navbar-fixed-bottom"
    }
  }
};
</script>
<style lang="scss">
#app {
  direction: rtl;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
