<template>
  <div class="khums">
    <div class="hero block is-dark is-bold is-small">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            حسابات رأس السنة الخمسية
          </h1>
        </div>
      </div>
    </div>
    <div class="mt-6 mx-3">
      <div
        v-for="e in khumsAnnualReports"
        :key="e.id"
        class="columns is-centered mt-4"
      >
        <div class="column is-8-fullhd is-9-desktop is-10-tablet is-12mobile">
          <div class="card">
            <header
              class="card-header"
              :style="e.freezedAt != null ? 'background-color: lightgrey;' : ''"
            >
              <div class="card-header-icon" @click="toggleExpansion(e.id)">
                <span class="icon has-text-success"
                  ><i
                    v-if="focus !== e.id"
                    class="mdi mdi-24px mdi-chevron-down"
                  ></i>
                  <i v-else class="mdi mdi-24px mdi-chevron-up"></i
                ></span>
              </div>
              <p class="card-header-title">
                {{ new Date(e.lastFreezedAt).getFullYear() + 1 }}
              </p>
              <div
                class="dropdown card-header-icon"
                :class="{ 'is-active': showAnnualStatementDropdown }"
                v-if="e.freezedAt == null"
                @click="toggleAnnualStatementDropdown(e.id)"
                v-click-outside="hideAnnualStatementDropdown"
              >
                <div class="dropdown-trigger">
                  <div
                    class="is-pulled-right"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu2"
                  >
                    <span class="icon is-small has-text-success">
                      <i class="mdi mdi-24px mdi-dots-vertical"></i>
                      <!--<i class="fas fa-angle-down" aria-hidden="true"></i>-->
                    </span>
                  </div>
                </div>
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                  <div class="dropdown-content">
                    <a href="#" class="dropdown-item">
                      <div
                        class="icon-text"
                        @click="setShowInitiateAccountFreeze(true)"
                      >
                        <span class="icon has-text-success ml-2"
                          ><i class="mdi mdi-24px mdi-check-all"></i
                        ></span>
                        <span>تم</span>
                      </div>
                    </a>
                    <hr class="dropdown-divider" />
                    <a href="#" class="dropdown-item">
                      <div
                        class="icon-text"
                        @click="deleteKhumsAnnualReport(e.id)"
                        :disabled="khumsAnnualReports.length <= 1"
                      >
                        <span class="icon has-text-danger ml-2"
                          ><i class="mdi mdi-24px mdi-delete"></i
                        ></span>
                        <span>حذف</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </header>
            <div class="card-content" :class="{ 'is-hidden': focus !== e.id }">
              <div class="content">
                <div class="block" style="text-align:right">
                  <i
                    >تاريخ حلول السنة الخمسية
                    {{ getNextKhumsDate(e.lastFreezedAt) }}</i
                  >
                </div>
                <h4 class="divider">الممتلكات</h4>
                <div
                  class="columns is-multiline is-vcentered"
                  style="text-align:right"
                >
                  <div
                    v-for="a in e.assets"
                    :key="a.id"
                    class="column is-3-fullhd is-4-desktop is-6-tablet is-12mobile"
                  >
                    <div class="box">
                      <div>
                        <button
                          class="delete is-pulled-left"
                          v-if="e.freezedAt === null"
                          @click="
                            {
                              deleteKhumsAsset(a.id, e.id);
                            }
                          "
                        ></button>
                        <span
                          class="tag is-light"
                          :class="getAssetTypeColor(a.type)"
                          >{{ getArabicAssetType(a.type) }}</span
                        >
                      </div>
                      <h5>{{ a.name }}</h5>
                      <div>{{ a.values[a.values.length - 1].value }} كرون</div>
                    </div>
                  </div>
                  <div
                    class="column is-3-fullhd is-4-desktop is-6-tablet is-12mobile"
                  >
                    <div class="box has-text-centered">
                      <h5>أضف ممتلكات</h5>
                      <button
                        class="button is-ghost"
                        @click="setShowAddNewKhumsAsset(true)"
                      >
                        <span class="icon is-small has-text-success"
                          ><i class="mdi mdi-24px mdi-plus-circle"></i
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
                <h4 class="divider">النتيجة</h4>
                <div class="mb-2">
                  <span class="icon is-pulled-right has-text-danger mx-1"
                    ><i class="mdi mdi-24px mdi-cash-multiple"></i
                  ></span>
                  قيمة الممتلكات الحالية،
                  <strong>{{ e.totalAccountValue }}</strong> كرون
                  <progress
                    class="progress is-small is-danger"
                    :value="e.totalAccountValue"
                    :max="e.totalAccountValue"
                    >{{ getPercent(e.totalAccountValue, e.totalAccountValue) }}
                  </progress>
                </div>
                <div class="mb-2">
                  <span class="icon is-pulled-right has-text-info mx-1"
                    ><i class="mdi mdi-24px mdi-bank"></i
                  ></span>
                  رأس مال العام السابق،
                  <strong>{{ e.lastKhumsCapital }} كرون</strong>
                  <progress
                    class="progress is-small is-info"
                    :value="e.lastKhumsCapital"
                    :max="e.totalAccountValue"
                    >{{ getPercent(e.lastKhumsCapital, e.totalAccountValue) }}
                  </progress>
                </div>
                <div class="mb-2">
                  <span class="icon is-pulled-right has-text-success mx-1"
                    ><i class="mdi mdi-24px mdi-bank"></i
                  ></span>
                  رأس المال للعام القادم،
                  <strong>{{ e.newKhumsCapital }} كرون</strong>
                  <progress
                    class="progress is-small is-success"
                    :value="e.newKhumsCapital"
                    :max="e.totalAccountValue"
                    >{{ getPercent(e.newKhumsCapital, e.totalAccountValue) }}
                  </progress>
                </div>
                <div class="mb-2">
                  <span class="icon is-pulled-right has-text-primary mx-1"
                    ><i class="mdi mdi-24px mdi-thumb-up"></i
                  ></span>
                  <div>
                    <strong>قيمة الخمس {{ e.khumsToPay }} كرون</strong>
                    <progress
                      class="progress is-small is-primary"
                      :value="e.khumsToPay"
                      :max="e.totalAccountValue"
                      >{{ getPercent(e.khumsToPay, e.totalAccountValue) }}%
                    </progress>
                  </div>
                </div>
                <div class="mb-2" v-if="e.paidKhums">
                  <span class="icon is-pulled-right has-text-primary mx-1"
                    ><i class="mdi mdi-24px mdi-thumb-up"></i
                  ></span>
                  <div>
                    <strong>قيمة الخمس المدفوع {{ e.paidKhums }} كرون</strong>
                    <progress
                      class="progress is-small is-primary"
                      :value="e.paidKhums"
                      :max="e.totalAccountValue"
                      >{{ getPercent(e.paidKhums, e.totalAccountValue) }}%
                    </progress>
                  </div>
                </div>
                <div class="divider" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateKhumsAssetDialog
      @refetchKhumsAnnualReports="refetchKhumsAnnualReports"
      @closeDialog="closeCreateKhumsAssetDialog"
      :isActive="showAddNewKhumsAsset"
      :accountId="this.$route.params.accountId"
      :annualReportId="this.focus"
    />
    <CloseAndCreateNewKhumsAnnualReportDialog
      v-if="getfocusedAnnualReport"
      @refetchKhumsAnnualReports="refetchKhumsAnnualReports"
      @closeDialog="closeCloseAndCreateNewKhumsAnnualReportDialog"
      :isActive="showInitiateAccountFreeze"
      :annualReportId="this.focus"
      :lastFreezedAt="getfocusedAnnualReport.lastFreezedAt"
      :khumsToPay="getfocusedAnnualReport.khumsToPay"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";
import CreateKhumsAssetDialog from "../components/partials/CreateKhumsAssetDialog.vue";
import CloseAndCreateNewKhumsAnnualReportDialog from "../components/partials/CloseAndCreateNewKhumsAnnualReportDialog.vue";
import ClickOutside from "vue-click-outside";

export default {
  name: "KhumsList",
  components: {
    CreateKhumsAssetDialog,
    CloseAndCreateNewKhumsAnnualReportDialog
  },
  data() {
    return {
      accountIdInput: this.$route.params.accountId,
      showAddNewKhumsAsset: false,
      showInitiateAccountFreeze: false,
      showAnnualStatementDropdown: false,
      focus: null
    };
  },
  apollo: {
    khumsAnnualReports: {
      query: gql`
        query khumsAnnualReports($accountId: UUID!) {
          khumsAnnualReports(accountId: $accountId) {
            id
            freezedAt
            totalAccountValue
            lastFreezedAt
            lastKhumsCapital
            khumsToPay
            newKhumsCapital
            paidKhums
            assets {
              id
              name
              type
              values {
                value
              }
            }
          }
        }
      `,
      variables() {
        return {
          accountId: this.accountIdInput
        };
      }
    }
  },
  computed: {
    getfocusedAnnualReport() {
      var annualReport = this.$apolloData.data.khumsAnnualReports.find(
        x => x.id === this.focus
      );
      if (annualReport) {
        return annualReport;
      } else {
        return null;
      }
    }
  },
  methods: {
    refetchKhumsAnnualReports() {
      this.$apollo.queries.khumsAnnualReports.refetch();
    },
    closeCreateKhumsAssetDialog() {
      this.showAddNewKhumsAsset = false;
    },
    setShowAddNewKhumsAsset(doShow) {
      this.showAddNewKhumsAsset = doShow;
    },
    setShowInitiateAccountFreeze(doShow) {
      this.showInitiateAccountFreeze = doShow;
    },
    closeCloseAndCreateNewKhumsAnnualReportDialog() {
      this.showInitiateAccountFreeze = false;
    },
    deleteKhumsAnnualReport(reportId) {
      if (!confirm("هل أنت متأكد من الحذف؟")) {
        return;
      }
      this.$apollo.mutate({
        mutation: gql`
          mutation deleteKhumsAnnualReport($reportId: UUID!) {
            deleteKhumsAnnualReport(reportId: $reportId)
          }
        `,
        variables: {
          reportId: reportId
        },
        // eslint-disable-next-line no-unused-vars
        update: (store, { data: { khumsAnnualReports } }) => {
          this.$apollo.queries.khumsAnnualReports.refetch();
        }
      });
    },
    deleteKhumsAsset(assetId, annualReportId) {
      if (!confirm("هل أنت متأكد من الحذف؟")) {
        return;
      }
      this.$apollo.mutate({
        mutation: gql`
          mutation deleteKhumsAsset($assetId: UUID!, $reportId: UUID!) {
            deleteKhumsAsset(assetId: $assetId, reportId: $reportId)
          }
        `,
        variables: {
          assetId: assetId,
          reportId: annualReportId
        },
        // eslint-disable-next-line no-unused-vars
        update: (store, { data: { khumsAnnualReports } }) => {
          this.$apollo.queries.khumsAnnualReports.refetch();
        }
      });
    },
    getArabicAssetType(backendAssetType) {
      if (backendAssetType === "MONEY") {
        return "مال";
      } else if (backendAssetType === "THINGS") {
        return "أشياء";
      } else if (backendAssetType === "GOLD") {
        return "ذهب";
      } else {
        return backendAssetType;
      }
    },
    getBackendAssetType(type) {
      if (type === "مال") {
        return "MONEY";
      } else if (type === "أشياء") {
        return "THINGS";
      } else if (type === "ذهب") {
        return "GOLD";
      } else {
        return type;
      }
    },
    getAssetTypeColor(assetType) {
      if (assetType === "MONEY") {
        return { "is-primary": true };
      } else if (assetType === "THINGS") {
        return { "is-success": true };
      } else if (assetType === "GOLD") {
        return { "is-warning": true };
      } else {
        return { "is-light": true };
      }
    },
    getPercent(val, ref) {
      return (100 * val) / ref;
    },
    toggleExpansion(id) {
      if (this.focus === id) {
        this.focus = null;
      } else {
        this.focus = id;
      }
    },
    toggleAnnualStatementDropdown(id) {
      this.showAnnualStatementDropdown = !this.showAnnualStatementDropdown;
      if (this.showAnnualStatementDropdown === true) {
        this.focus = id;
      }
    },
    getNextKhumsDate(lastKhomsDate) {
      if (lastKhomsDate == null) {
        return "N/A";
      } else {
        lastKhomsDate = new Date(lastKhomsDate);
        return new Date(
          lastKhomsDate.getFullYear() + 1,
          lastKhomsDate.getMonth(),
          lastKhomsDate.getDate()
        ).toLocaleDateString();
      }
    },
    getDate(dateTime) {
      if (dateTime == null) {
        return "N/A";
      } else {
        dateTime = new Date(dateTime);
        return new Date(
          dateTime.getFullYear(),
          dateTime.getMonth(),
          dateTime.getDate()
        ).toLocaleDateString();
      }
    },
    getKhums(currentValue, lastValue) {
      return (currentValue - lastValue) / 5;
    },
    hideAnnualStatementDropdown() {
      this.showAnnualStatementDropdown = false;
    }
  },
  //https://www.npmjs.com/package/vue-click-outside
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el;
  },
  directives: {
    ClickOutside
  }
};
</script>

<style lang="sass">
// Progress bar highly inpired by Furkan Poyraz's answer: https://stackoverflow.com/questions/51174747/how-to-stack-progress-bars-into-one-bar-with-bulma-css

.progress-bar
  float: right
  height: 100%
</style>
