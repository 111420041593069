<template>
  <nav class="navbar is-fixed-bottom is-dark">
    <div class="navbar-brand">
      <router-link
        class="navbar-item is-expanded is-block has-text-centered"
        to="/"
      >
        <span class="icon has-text-success"
          ><i class="mdi mdi-24px mdi-home"></i
        ></span>
        <p class="is-size-7">الصفحة الرئيسية</p>
      </router-link>
      <router-link
        to="/khums"
        class="navbar-item is-expanded is-block has-text-centered"
      >
        <span class="icon has-text-success"
          ><i class="mdi mdi-24px mdi-chart-pie"></i
        ></span>
        <p class="is-size-7">رأس السنة الخمسية</p></router-link
      >
      <!--
      <router-link
        to="/khums-list"
        class="navbar-item is-expanded is-block has-text-centered"
        ><span class="icon has-text-success"
          ><span class="material-icons">account_balance</span></span
        >
        <p class="is-size-7">حساب الخمس</p></router-link
      >
      <router-link
        class="navbar-item is-expanded is-block has-text-centered"
        to="/users"
      >
        <span class="icon has-text-success"
          ><span class="material-icons">account_circle</span></span
        >
        <p class="is-size-7">المستخدم</p>
      </router-link>
      -->
      <router-link
        to="/user-profile"
        class="navbar-item is-expanded is-block has-text-centered"
      >
        <span class="icon has-text-success"
          ><figure class="image is-24x24">
            <img class="is-rounded" :src="userPic" /></figure
        ></span>

        <p class="is-size-7">المستخدم</p></router-link
      >
    </div>
  </nav>
</template>
<script>
export default {
  name: "NavSignedInUser",
  data() {
    return {
      userPic: this.$auth.user.picture
    };
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>
