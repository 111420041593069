<template>
  <div class="home">
    <div class="block mt-6">
      <div class="hero is-dark is-bold">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              بسم الله الرحمن الرحيم
            </h1>
            <h2 class="subtitle mt-3">
              ﴿وَاعْلَمُوا أَنَّمَا غَنِمْتُمْ مِنْ شَيْءٍ فَأَنَّ لِلَّهِ
              <span style="color:lightgreen;">خُمُسَهُ</span> وَلِلرَّسُولِ
              وَلِذِي الْقُرْبَى وَالْيَتَامَى وَالْمَسَاكِينِ وَابْنِ
              السَّبِيلِ﴾
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="block m-3">
      <button
        v-if="!$auth.isAuthenticated"
        @click="login"
        class="button is-xl is-primary"
      >
        تسجيل الدخول
      </button>
      <h3 v-if="$auth.isAuthenticated" class="is-size-3 welcome">
        أهلا وسهلا {{ $auth.user.name }}!
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>
