<template>
  <div class="khums">
    <div class="hero block is-dark is-bold is-small">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            حسابات رأس السنة الخمسية
          </h1>
        </div>
      </div>
    </div>
    <div class="mt-6 mx-3">
      <div class="columns is-multiline is-centered is-vcentered">
        <div
          v-for="(e, index) in activeKhumsAccounts"
          :key="e.id"
          class="column is-3-fullhd is-4-desktop is-6-tablet is-12mobile"
        >
          <div class="card">
            <router-link
              :to="{ name: 'khumsList', params: { accountId: e.id } }"
            >
              <header class="card-header">
                <div class="card-header-icon">
                  <span class="icon has-text-success"
                    ><i class="mdi mdi-24px mdi-launch"></i
                  ></span>
                </div>
                <p class="card-header-title">{{ index }} - {{ e.name }}</p>
              </header>
            </router-link>
            <div class="card-content">
              <div class="content">
                <table
                  v-if="e.annualReports && e.annualReports[0]"
                  class="table is-striped is-hoverable is-narrow"
                >
                  <tbody>
                    <tr>
                      <td>قيمة الممتلكات الحالية</td>
                      <td>
                        {{ e.annualReports[0].totalAccountValue }}
                      </td>
                    </tr>
                    <tr>
                      <td>رأس المال السابق</td>
                      <td>
                        {{ e.annualReports[0].lastKhumsCapital }}
                      </td>
                    </tr>
                    <tr>
                      <td>تقدير رأس المال القادم</td>
                      <td>
                        {{ e.annualReports[0].newKhumsCapital }}
                      </td>
                    </tr>
                    <tr>
                      <td>تقدير قيمة الخمس القادم</td>
                      <td>
                        {{ e.annualReports[0].khumsToPay }}
                      </td>
                    </tr>
                    <tr>
                      <td>تاريخ حلول السنة الخمسية</td>
                      <td>
                        {{ getNextKhumsDate(e.annualReports[0].lastFreezedAt) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else style="height: 100%">
                  <strong>أضف معلومات</strong>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <a href="#" class="card-footer-item"
                ><span class="icon has-text-danger"
                  ><i
                    class="mdi mdi-24px mdi-delete"
                    @click="
                      {
                        deleteKhumsAccount(e.id);
                      }
                    "
                  ></i></span
              ></a>
            </footer>
          </div>
        </div>
        <div class="column is-3-desktop is-6-tablet is-12mobile">
          <button
            class="button is-ghost"
            @click="setShowAddNewKhumsAccount(true)"
          >
            <span
              class="icon is-large has-text-success-light has-background-success"
              ><i class="mdi mdi-48px mdi-plus-circle"></i
            ></span>
          </button>
        </div>
      </div>
    </div>
    <CreateKhumsAccountDialog
      @refetchActiveKhumsAccount="refetchActiveKhumsAccount"
      @closeDialog="closeCreateKhumsAccountDialog"
      :isActive="showAddNewKhumsAccount"
    />
  </div>
</template>
-->
<script>
import gql from "graphql-tag";
import CreateKhumsAccountDialog from "../components/partials/CreateKhumsAccountDialog.vue";

export default {
  name: "Khums",
  components: {
    CreateKhumsAccountDialog
  },
  data() {
    return {
      showAddNewKhumsAccount: false
    };
  },
  apollo: {
    activeKhumsAccounts: {
      query: gql`
        query activeKhumsAccount {
          activeKhumsAccounts {
            id
            name
            description
            createdAt
            annualReports {
              totalAccountValue
              lastFreezedAt
              lastKhumsCapital
              khumsToPay
              newKhumsCapital
            }
          }
        }
      `
    }
  },
  methods: {
    setShowAddNewKhumsAccount(doShow) {
      this.showAddNewKhumsAccount = doShow;
    },
    closeCreateKhumsAccountDialog() {
      this.showAddNewKhumsAccount = false;
    },
    refetchActiveKhumsAccount() {
      this.$apollo.queries.activeKhumsAccounts.refetch();
    },
    getNextKhumsDate(lastKhumsDate) {
      if (lastKhumsDate == null) {
        return "N/A";
      } else {
        lastKhumsDate = new Date(lastKhumsDate);
        return new Date(
          lastKhumsDate.getFullYear() + 1,
          lastKhumsDate.getMonth(),
          lastKhumsDate.getDate()
        ).toLocaleDateString();
      }
    },
    getDate(dateTime) {
      if (dateTime == null) {
        return "N/A";
      } else {
        dateTime = new Date(dateTime);
        return new Date(
          dateTime.getFullYear(),
          dateTime.getMonth(),
          dateTime.getDate()
        ).toLocaleDateString();
      }
    },
    getKhums(currentValue, lastValue) {
      return (currentValue - lastValue) / 5;
    },
    deleteKhumsAccount(accountIdToDelete) {
      if (!confirm("هل أنت متأكد من حذف السنة الخمسية؟")) {
        return;
      }
      this.$apollo.mutate({
        mutation: gql`
          mutation deleteKhumsAccount($accountId: UUID!) {
            deleteKhumsAccount(accountId: $accountId)
          }
        `,
        variables: {
          accountId: accountIdToDelete
        },
        // eslint-disable-next-line no-unused-vars
        update: (store, { data: { activeKhumsAccounts } }) => {
          this.$apollo.queries.activeKhumsAccounts.refetch();
        }
      });
    },
    khumsAnnualReports(accountId) {
      this.$apollo.query({
        query: gql`
          query khumsAnnualReports($accountId: UUID!) {
            khumsAnnualReports(accountId: $accountId) {
              id
              name
            }
          }
        `,
        variables: {
          accountId: accountId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
table > *:first-child:not(thead) td:first-child {
  font-weight: bold;
}
</style>
