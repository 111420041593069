import Vue from "vue";
import { getInstance } from "../auth";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from "@apollo/client/core";
import VueApollo from "@vue/apollo-option";
import { onError } from "apollo-link-error";
import { setContext } from "@apollo/client/link/context";

// Good site for setting up vue + apollo
// https://stackabuse.com/building-graphql-apis-with-vue-js-and-apollo-client/ (this file, unless another reference specified)

// https://github.com/apollographql/apollo-client/issues/6108
let token;
const getToken = async () => {
  const authService = getInstance();
  if (authService.isAuthenticated) {
    token = await authService.getTokenSilently();
    const authorization = token ? `Bearer ${token}` : "";
    return authorization;
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return getToken();
    }
  });
};

// https://blog.pusher.com/handling-authentication-in-graphql-auth0/
// https://stackoverflow.com/questions/57747225/apollo-graphql-setting-header-to-authmiddleware-not-working
const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: token || null
    }
  };
});

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (networkError.statusCode === 401) {
      // remove cached token on 401 from the server
      token = null;
    }
  }
});

const authFlowLink = authLink.concat(errorLink);

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: "https://khums-api.badra.se/graphql"
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authFlowLink.concat(httpLink),
  cache: new InMemoryCache()
});

// Install the Vue plugin
Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});
