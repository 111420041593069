<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <button
          class="delete"
          aria-label="close"
          @click="closeWindow()"
        ></button>
        <p class="modal-card-title">إقفال هذه السنة الخمسية إنشاء جديدة</p>
      </header>
      <section class="modal-card-body" style="text-align:right">
        <div class="field">
          <label class="label">التأريخ الذي دُفع فيه الخمس</label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': invalidEntryFreezeAt }"
              type="date"
              :value="freezeAt && freezeAt.toISOString().split('T')[0]"
              @input="freezeAt = $event.target.valueAsDate"
            />
          </div>
          <p class="help is-danger" v-show="invalidEntryFreezeAt">
            يجب أن يكون التاريخ ما بين {{ lastFreezedAt.split("T")[0] }} و
            {{ freezeByLatest.toISOString().split("T")[0] }}.
          </p>
        </div>
        <div class="field">
          <label class="label">المبلغ الذي دُفع</label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': invalidEntryPaidKhums }"
              type="number"
              placeholder="5000"
              v-model="paidKhums"
            />
          </div>
          <p class="help is-danger" v-show="invalidEntryPaidKhums">
            يجب أن يكون مبلغ الخمس المدفوع لا يكون أقل من الملغ المُقدّر، وهو
            {{ khumsToPay }} كرون
          </p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-primary ml-5"
          @click="closeAndCreateNewKhumsAnnualReport()"
        >
          أضف
        </button>
        <button class="button is-primary is-light" @click="closeWindow()">
          إلغاء
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CloseAndCreateNewKhumsAnnualReportDialog",
  props: {
    isActive: Boolean,
    annualReportId: String,
    lastFreezedAt: String,
    khumsToPay: Number
  },
  data() {
    return {
      freezeAt: null,
      paidKhums: null,
      invalidEntryPaidKhums: false,
      invalidEntryFreezeAt: false,
      freezeByLatest: this.getNextKhumsDate(this.lastFreezedAt)
    };
  },
  methods: {
    closeWindow() {
      this.freezeAt = null;
      this.paidKhums = null;
      this.invalidEntryPaidKhums = false;
      this.invalidEntryFreezeAt = false;
      this.$emit("closeDialog");
    },
    closeAndCreateNewKhumsAnnualReport() {
      if (this.inputsAreValid() === false) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation closeAndCreateNewKhumsAnnualReport(
              $input: CloseAndCreateNewKhumsAnnualReportInput!
            ) {
              closeAndCreateNewKhumsAnnualReport(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              reportId: this.annualReportId,
              freezeAt: this.freezeAt,
              paidKhums: this.paidKhums
            }
          }
        })
        .then(() => {
          this.closeWindow();
          this.$emit("refetchKhumsAnnualReports");
        });
    },
    inputsAreValid() {
      if (this.paidKhums >= this.khumsToPay) {
        this.invalidEntryPaidKhums = false;
      } else {
        this.invalidEntryPaidKhums = true;
      }
      var freezeAt = new Date(this.freezeAt);
      var lastFreezedAt = new Date(this.lastFreezedAt);
      if (freezeAt > lastFreezedAt && freezeAt <= this.freezeByLatest) {
        this.invalidEntryFreezeAt = false;
      } else {
        this.invalidEntryFreezeAt = true;
      }
      return !this.invalidEntryPaidKhums && !this.invalidEntryFreezeAt;
    },
    getNextKhumsDate(lastKhomsDate) {
      if (lastKhomsDate == null) {
        return "N/A";
      } else {
        lastKhomsDate = new Date(lastKhomsDate);
        return new Date(
          lastKhomsDate.getFullYear() + 1,
          lastKhomsDate.getMonth(),
          lastKhomsDate.getDate()
        );
      }
    }
  }
};
</script>
