<template>
  <div class="home">
    <div class="hero block is-dark is-bold is-small">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            المستخدم
          </h1>
        </div>
      </div>
    </div>
    <div class="columns is-centered mt-6 mx-3">
      <div class="column is-6-fullhd is-7-desktop is-10-tablet is-12mobile">
        <table class="table is-fullwidth is-striped is-hoverable">
          <tbody>
            <tr>
              <th>الإسم</th>
              <td>{{ $auth.user.name }}</td>
            </tr>
            <tr>
              <th>البريد الالكتروني</th>
              <td>{{ $auth.user.email }}</td>
            </tr>
            <tr>
              <th>تفعيل التنبيه لقرب موعد الخمس</th>
              <td>
                <label class="checkbox">
                  <input type="checkbox" v-model="enableReminder" />
                </label>
              </td>
            </tr>
            <tr>
              <th>تسجيل الخروج</th>
              <td>
                <a
                  @click="logout"
                  class="navbar-item is-expanded is-block has-text-centered"
                  ><span class="icon has-text-danger"
                    ><i class="mdi mdi-24px mdi-door"></i></span
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserProfile",
  data() {
    return {
      enableReminder: false,
      reminder: null
    };
  },
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    notify() {
      if (
        this.enableReminder === true &&
        Notification.permission === "granted"
      ) {
        new Notification("test!");
      }
    }
  },
  watch: {
    enableReminder: {
      handler() {
        if (this.enableReminder === true) {
          // Let's check if the browser supports notifications
          if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
          } else if (
            Notification.permission === "granted" &&
            (Notification.permission !== "denied" ||
              Notification.permission === "default")
          ) {
            Notification.requestPermission(function(permission) {
              // If the user accepts, let's create a notification
              if (permission === "granted") {
                new Notification("تم!");
              }
            });
          }
          this.reminder = setInterval(() => this.notify(), 10000);
        } else {
          clearInterval(this.reminder);
        }
      }
    }
  }
};
</script>
