import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "../auth/authGuard";
import Home from "../views/Home.vue";
import Khums from "../views/Khums.vue";
import KhumsList from "../views/KhumsList.vue";
import UserProfile from "../views/UserProfile.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/About.vue")
    },
    {
      path: "/khums",
      name: "khums",
      component: Khums,
      beforeEnter: authGuard
    },
    {
      path: "/khums-list/:accountId",
      name: "khumsList",
      component: KhumsList,
      beforeEnter: authGuard
    },
    {
      path: "/user-profile",
      name: "profile",
      component: UserProfile,
      beforeEnter: authGuard
    }
  ]
});
