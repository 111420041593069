<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <button
          class="delete"
          aria-label="close"
          @click="closeWindow()"
        ></button>
        <p class="modal-card-title">إضافة رأس سنة خمسية جديدة</p>
      </header>
      <section class="modal-card-body" style="text-align:right">
        <div class="field">
          <label class="label">إسم لرأس السنة الخمسية</label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': invalidEntryAccountName }"
              type="text"
              placeholder="الممتلكات الشخصية"
              v-model="accountName"
            />
          </div>
          <p class="help is-danger" v-show="invalidAssetName">
            هذا الحقل إلزامي
          </p>
        </div>
        <div class="field">
          <label class="label">تفاصيل حول هذه الرأس السنة الخمسية</label>
          <div class="control">
            <textarea
              class="textarea"
              rows="2"
              placeholder="الممتلكات الشخصية"
              v-model="accountDescription"
            />
          </div>
        </div>
        <div class="field">
          <label class="label">رأس مال العام الماضي</label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': invalidEntryInitialKhumsCapital }"
              type="number"
              placeholder="50000"
              v-model="initialKhumsCapital"
            />
          </div>
          <p class="help is-danger" v-show="invalidEntryInitialKhumsCapital">
            هذا الحقل إلزامي ويجب أن يتكون فقط من أرقام
          </p>
        </div>
        <div class="field">
          <label class="label">تأريخ تخميس آخر سنة</label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': invalidEntryLastFreezedAt }"
              type="date"
              :value="
                lastFreezedAt && lastFreezedAt.toISOString().split('T')[0]
              "
              @input="lastFreezedAt = $event.target.valueAsDate"
            />
          </div>
          <p class="help is-danger" v-show="invalidEntryLastFreezedAt">
            هذا الحقل إلزامي
          </p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary ml-5" @click="createKhumsAccount()">
          أضف
        </button>
        <button class="button is-primary is-light" @click="closeWindow()">
          إلغاء
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CreateKhumsAccountDialog",
  props: {
    isActive: Boolean
  },
  data() {
    return {
      accountName: null,
      accountDescription: null,
      initialKhumsCapital: null,
      lastFreezedAt: null,
      invalidEntryAccountName: false,
      invalidEntryInitialKhumsCapital: false,
      invalidEntryLastFreezedAt: false
    };
  },
  methods: {
    closeWindow() {
      this.accountName = null;
      this.accountDescription = null;
      this.initialKhumsCapital = null;
      this.lastFreezedAt = null;
      this.invalidEntryAccountName = false;
      this.invalidEntryInitialKhumsCapital = false;
      this.invalidEntryLastFreezedAt = false;
      this.$emit("closeDialog");
    },
    createKhumsAccount() {
      if (this.inputsAreValid() === false) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createKhumsAccount($input: CreateKhumsAccountInput!) {
              createKhumsAccount(input: $input) {
                name
                description
                createdAt
                updatedAt
              }
            }
          `,
          variables: {
            input: {
              name: this.accountName,
              description: this.accountDescription,
              initialKhumsCapital: this.initialKhumsCapital,
              lastFreezedAt: this.lastFreezedAt
            }
          }
        })
        .then(() => {
          this.closeWindow();
          this.$emit("refetchActiveKhumsAccount");
        });
    },
    inputsAreValid() {
      this.invalidEntryAccountName = this.accountName ? false : true;
      if (this.initialKhumsCapital && !isNaN(this.initialKhumsCapital)) {
        this.invalidEntryInitialKhumsCapital = false;
      } else {
        this.invalidEntryInitialKhumsCapital = true;
      }
      this.invalidEntryLastFreezedAt = this.lastFreezedAt ? false : true;
      return (
        !this.invalidEntryAccountName &&
        !this.invalidEntryInitialKhumsCapital &&
        !this.invalidEntryLastFreezedAt
      );
    }
  }
};
</script>
