import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@mdi/font/css/materialdesignicons.css";
import "bulma/css/bulma.css";
import "./mystyles.scss";
import { apolloProvider } from "./graphql/apollo";
// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";
// Import the plugin here
import { Auth0Plugin } from "./auth";

// For ssl redirect: https://github.com/vuejs-templates/webpack/issues/931
//var env = process.env.NODE_ENV || "development";
//if (env === "production" && window.location.protocol !== "https:") {
//  window.location.href = "https://khums-app.herokuapp.com";
//}

// Install the authentication plugin
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueMeta, {
  // optional pluginOptions
  // refreshOnceOnNavigation: true
});

Vue.config.productionTip = false;

new Vue({
  router,
  // inject apolloProvider here like vue-router or vuex
  apolloProvider,
  render: h => h(App)
}).$mount("#app");
