<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <button
          class="delete"
          aria-label="close"
          @click="closeWindow()"
        ></button>
        <p class="modal-card-title">إضافة ممتلكات</p>
      </header>
      <section class="modal-card-body" style="text-align:right">
        <div class="field">
          <label class="label">الإسم</label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': invalidAssetName }"
              type="text"
              placeholder="إسم الحاجة"
              v-model="assetName"
            />
          </div>
          <p class="help is-danger" v-show="invalidAssetName">
            هذا الحقل إلزامي
          </p>
        </div>
        <div class="field">
          <label class="label">النوعية</label>
          <div class="control">
            <div class="select" :class="{ 'is-danger': invalidAssetType }">
              <select
                :value="getArabicAssetType(assetType)"
                @change="assetType = getBackendAssetType($event.target.value)"
              >
                <option>مال</option>
                <option>ذهب</option>
                <option>أشياء</option>
              </select>
            </div>
          </div>
          <p class="help is-danger" v-show="invalidAssetType">
            هذا الحقل إلزامي
          </p>
        </div>
        <div class="field">
          <label class="label">القيمة</label>
          <div class="control">
            <input
              class="input"
              :class="{ 'is-danger': invalidAssetValue }"
              type="number"
              placeholder="5000"
              v-model="assetValue"
            />
          </div>
          <p class="help is-danger" v-show="invalidAssetValue">
            هذا الحقل إلزامي ويجب أن يتكون فقط من أرقام
          </p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary ml-5" @click="createKhumsAsset()">
          أضف
        </button>
        <button class="button is-primary is-light" @click="closeWindow()">
          إلغاء
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CreateKhumsAssetDialog",
  props: {
    isActive: Boolean,
    accountId: String,
    annualReportId: String
  },
  data() {
    return {
      assetName: null,
      assetType: null,
      assetValue: null,
      invalidAssetName: false,
      invalidAssetType: false,
      invalidAssetValue: false
    };
  },
  methods: {
    closeWindow() {
      this.assetName = null;
      this.assetType = null;
      this.assetValue = null;
      this.invalidAssetName = false;
      this.invalidAssetType = false;
      this.invalidAssetValue = false;
      this.$emit("closeDialog");
    },
    createKhumsAsset() {
      if (this.inputsAreValid() === false) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createKhumsAsset($input: CreateKhumsAssetInput!) {
              createKhumsAsset(input: $input) {
                name
                type
                values {
                  value
                }
              }
            }
          `,
          variables: {
            input: {
              accountId: this.accountId,
              annualReportId: this.annualReportId,
              name: this.assetName,
              type: this.assetType,
              value: this.assetValue
            }
          }
        })
        .then(() => {
          this.closeWindow();
          this.$emit("refetchKhumsAnnualReports");
        });
    },
    getArabicAssetType(backendAssetType) {
      if (backendAssetType === "MONEY") {
        return "مال";
      } else if (backendAssetType === "THINGS") {
        return "أشياء";
      } else if (backendAssetType === "GOLD") {
        return "ذهب";
      } else {
        return backendAssetType;
      }
    },
    getBackendAssetType(type) {
      if (type === "مال") {
        return "MONEY";
      } else if (type === "أشياء") {
        return "THINGS";
      } else if (type === "ذهب") {
        return "GOLD";
      } else {
        return type;
      }
    },
    inputsAreValid() {
      if (this.assetName) {
        this.invalidAssetName = false;
      } else {
        this.invalidAssetName = true;
      }
      if (this.assetType) {
        this.invalidAssetType = false;
      } else {
        this.invalidAssetType = true;
      }
      if (this.assetValue && !isNaN(this.assetValue)) {
        this.invalidAssetValue = false;
      } else {
        this.invalidAssetValue = true;
      }
      return (
        !this.invalidAssetName &&
        !this.invalidAssetType &&
        !this.invalidAssetValue
      );
    }
  }
};
</script>
